import ArticleIcon from '@mui/icons-material/Article';
import CloseIcon from '@mui/icons-material/Close';
import './FileUploadPreview.css'

export default function FileUploadPreview({ value, handleDownload, handleDelete,deleteButton,qid }) {
    return (
        <div className='file-upload-container' style={{display:value === undefined || value === null || value === "" ? "none": "flex",}}>
            <div className='file-upload-container-1' title='Download File' onClick={() => handleDownload(qid)}>
                <ArticleIcon style={{ color: "#ff5858" }} />
                <div className='file-upload-value' >
                    {value}
                </div>
            </div>
            <CloseIcon title="Remove File" style={{ cursor: "pointer", display:deleteButton==="false"?"none":"" }} onClick={() => handleDelete()} />
        </div>
    )
}