import { useEffect, useState } from "react";
import useAuth from "../../hooks/useAuth";
import { Button } from "@mui/material";
import { styled } from "@mui/material/styles";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { toast } from "react-toastify";
import "./Report.css";
import FileUploadPreview from "../Helpers/FileUploadPreview/FileUploadPreview";
import MentorReport from "./MentorReport";
import axios from "axios";
import { useParams } from "react-router-dom";
import Loading from "../Helpers/Loading/Loading";
import { gridColumnsTotalWidthSelector } from "@mui/x-data-grid";

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

const Report = (props) => {
  const { stationId } = useParams();
  const { auth, setAuth } = useAuth();
  const [midsemReport, setMidsemReport] = useState(null);
  const [endsemReport, setEndsemReport] = useState(null);
  const [midsemUploaded, setMidsemUploaded] = useState(false);
  const [endsemUploaded, setEndsemUploaded] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleFileUpload = (event, reportType) => {
    const file = event.target.files[0];
    if (file && (file.type === "application/pdf" || file.type === "application/vnd.openxmlformats-officedocument.wordprocessingml.document")) {
      if (reportType === "midsem") {
        setMidsemReport(file);
      } else {
        setEndsemReport(file);
      }
    } else if (file) {
      toast.error("Please upload a PDF file");
    }
  };

  const handleRemoveFile = (reportType) => {
    if (reportType === "midsem") {
      setMidsemReport(null);
      setMidsemUploaded(false);
      document.getElementById('midsem-file-input').value = '';
    } else {
      setEndsemReport(null);
      setEndsemUploaded(false);
      document.getElementById('endsem-file-input').value = '';
    }
  };

  const handleDownload = (reportType) => {
    const file = reportType === "midsem" ? midsemReport : endsemReport;
    if (file) {
      const fileURL = URL.createObjectURL(file);
      window.open(fileURL, '_blank');
    } else {
      toast.error(`No ${reportType} report uploaded yet.`);
    }
  };

  const handleSubmit = async (reportType) => {
    const report = reportType === "midsem" ? midsemReport : endsemReport;
    if (!report) {
      toast.error(`Please upload a ${reportType} report first`);
      return;
    }

    try {
      const formData = new FormData();
      formData.append("report", report);
      formData.append("type", reportType);

      const res = await axios.post("/api/report/uploadReport", formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
      });

      if (res.status === 200) {
        toast.success(`${reportType.charAt(0).toUpperCase() + reportType.slice(1)} report submitted successfully`);
        if (reportType === "midsem") {
          setMidsemUploaded(true);
        } else {
          setEndsemUploaded(true);
        }
      }
    } catch (error) {
      console.error(error);
      if (error.response?.data === "Token Expired") {
        toast.error("Your session has expired. Please log in again.");
        localStorage.removeItem("user");
        setAuth({});
      } else {
        toast.error(`Error submitting ${reportType} report`);
      }
    }
  };

  const handleTemplateDownload = async (reportType) => {
    const toastId = toast.loading("Downloading Template...");
    try {
      const res = await axios.post("/api/report/downloadTemplate", 
        { type: reportType },
        { responseType: 'blob' }
      );

      if (res.status === 200) {
        const url = URL.createObjectURL(new Blob([res.data], { type: res.headers["content-type"] }));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `${reportType}-template.${res.headers["content-type"].split('/').pop() ==="octet-stream" ? "docx" : res.headers["content-type"].split('/').pop()}`);
        document.body.appendChild(link);
        link.click();
        link.remove();
        URL.revokeObjectURL(url);
      }
    } catch (error) {
      console.error(error);
      if (error.response?.data === "Token Expired") {
        toast.error("Your session has expired. Please log in again.");
        localStorage.removeItem("user");
        setAuth({});
      } else {
        toast.error("Error downloading template");
      }
    } finally {
      toast.dismiss(toastId);
    }
  };

  const handleFetchReport = async (reportType) => {
    setLoading(true);
    try {
      const res = await axios.post("/api/report/downloadReportForStudent",
        { type: reportType },
        { responseType: 'blob' }
      );

      if (res.status === 200) {
          const file = new File([res.data], `${reportType}.${res.headers["content-type"].split('/').pop() ==="octet-stream" ? "docx" : res.headers["content-type"].split('/').pop()}`, { type: res.headers["content-type"] });
          if (reportType === "midsem") {
            setMidsemReport(file);
            setMidsemUploaded(true);
          } else if (reportType === "endsem") {
            setEndsemReport(file);
            setEndsemUploaded(true);
          }
        
      }
    } catch (error) {
      console.error(error);
      if (error.response?.data === "Token Expired") {
        toast.error("Your session has expired. Please log in again.");
        localStorage.removeItem("user");
        setAuth({});
      }
    }finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (stationId) {
      handleFetchReport("midsem");
      handleFetchReport("endsem");
    }
  }, [stationId]);

  const StudentReport = () => {
    return (
      <div className="report-container">
        <h1 className="report-title">Upload Reports</h1>
        <div className="report-sections">
          <div className="report-section">
            <h2>Midsem Report</h2>
            <div className="report-upload">
              <Button
                component="label"
                variant="contained"
                startIcon={<CloudUploadIcon />}
              >
                Upload Midsem Report
                <VisuallyHiddenInput
                  id='midsem-file-input'
                  type="file"
                  onChange={(e) => handleFileUpload(e, "midsem")}
                  accept="application/pdf, application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                />
              </Button>
              <FileUploadPreview
                value={midsemReport?.name}
                handleDownload={() => handleDownload("midsem")}
                handleDelete={() => handleRemoveFile("midsem")}
                deleteButton={!midsemUploaded}
                qid="midsem"
              />
            </div>
            <div style={{display:"flex", justifyContent:"space", gap:"2rem"}}>
              <Button
                variant="contained"
                color="primary"
                onClick={() => handleSubmit("midsem")}
                disabled={!midsemReport}
                className="submit-button"
              >
                Submit Midsem Report
              </Button>
              <Button 
                variant="contained"
                onClick={() => handleTemplateDownload("midsem")}
              >
                Download Midsem Template
              </Button>
            </div>
          </div>
          <div className="report-section">
            <h2>Endsem Report</h2>
            <div className="report-upload">
              <Button
                component="label"
                variant="contained"
                startIcon={<CloudUploadIcon />}
              >
                Upload Endsem Report
                <VisuallyHiddenInput
                  id='endsem-file-input'
                  type="file"
                  onChange={(e) => handleFileUpload(e, "endsem")}
                  accept="application/pdf, application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                />
              </Button>
              <FileUploadPreview
                value={endsemReport?.name}
                handleDownload={() => handleDownload("endsem")}
                handleDelete={() => handleRemoveFile("endsem")}
                deleteButton={!endsemUploaded}
                qid="endsem"
              />
            </div>
            <div style={{display:"flex", justifyContent:"", gap:"2rem"}}>
              <Button
                variant="contained"
                color="primary"
                onClick={() => handleSubmit("endsem")}
                disabled={!endsemReport}
                className="submit-button"
              >
                Submit Endsem Report
              </Button>
              <Button 
                variant="contained"
                onClick={() => handleTemplateDownload("endsem")}
              >
                Download Endsem Template
              </Button>
            </div>
          </div>
        </div>
      </div>
    );
  };

  return auth?.userType === "Student" ? 
    (!loading ? <StudentReport /> : <Loading />) : 
    <MentorReport students={props?.students} getStudentList={props?.getStudentList} />;
};

export default Report;