import React, { useState, useEffect } from 'react';
import "./pdf.css"

const MyPDFViewer = ({ pdfUrl }) => {
  const [isValidPdf, setIsValidPdf] = useState(true);
  const [isPdfUrl, setIsPdfUrl] = useState(true);

  useEffect(() => {
    setIsPdfUrl(pdfUrl?.toLowerCase()?.endsWith('.pdf') || pdfUrl?.startsWith('blob:'));
  }, [pdfUrl]);

  const handleIFrameError = () => {
    setIsValidPdf(false);
  }
  if (pdfUrl === "docx"){
    
    return (
      <div className="student-details-empty">
            <p>Report/Template downloaded</p>
        </div>
    );
  }
  
  return (
    <div className="pdf-viewer-container">
      {isPdfUrl ? (
        isValidPdf ? (
          <iframe
            src={pdfUrl}
            className="pdf-viewer-iframe"
            title="PDF Viewer"
            onError={handleIFrameError}
          />
        ) : (
          <div className="pdf-error-message">
            <p>This PDF file cannot be displayed.</p>
          </div>
        )
      ) : (
        <div className="student-details-empty">
            <p>Report not submitted by the Student.</p>
        </div>
      )}
    </div>
  );
};

export default MyPDFViewer;