import { Routes, Route } from "react-router-dom";
import Home from "../Home/Home";
import Stations from "../Stations/Stations";
import StationOptions from "../StationOptions/StationOptions";
import Announcement from "../Announcements/Announcements";
import Diary from "../Assignments/MentorAssignments/Diary";
import NoStudentSelected from "../Helpers/NoStudentSelected/NoStudentSelected";
import Scores from "../Scores/Scores";
import Statistics from "../Statistics/Statistics";
import useAuth from "../../hooks/useAuth";
import { useState } from "react";
import axios from "axios";
import CreateQuiz from "../Quiz/CreateQuiz/CreateQuiz";
import Grading from "../Grading/Grading";
import Report from "../Report/Report";
import Evaluation from "../Evaluation/Evaluation";

export default function MentorUser() {
  const { auth, setAuth } = useAuth();
  const [stations, setStations] = useState([]);
  const [navbarAssignments, setNavbarAssignments] = useState([]);
  const [students, setStudents] = useState([]);
  const [submissions, setSubmissions] = useState([]);

  async function getMentor() {
    try {
      if (auth) {
        const res = await axios.post("/api/mentor/getMentor", {
          email: auth._id,
        });
        setStations(res.data.stations);
      }
    } catch (error) {
      console.log(error);
      if (error?.response.data === "Token Expired") {
        alert("Your session is expired. Please Log in again.");
      }
      localStorage.removeItem("user");
    }
  }

  const getStudentList = async (stationId) => {
    try {
      if (auth?.userType !== "Student") {
        const data = await axios.post(`/api/student/getByStation`, {
          station: stationId,
        });
        setStudents(data.data);
      }
    } catch (error) {
      console.log(error);
      if (error.response.data === "Token Expired") {
        alert("Your session is expired. Please Log in again.");
      }
      localStorage.removeItem("user");
      setAuth({});
    }
  };

  const getAssignments = async (stationId) => {
    try {
      if (auth?.userType !== "Student") {
        const data = await axios.post(`/api/assignment/getStationAssignment`, {
          stationID: stationId,
        });
        setNavbarAssignments(
          data.data.map((e) => ({
            ...e,
            id: e._id,
          }))
        );
      }
    } catch (error) {
      console.log(error);
      if (error.response.data === "Token Expired") {
        alert("Your session is expired. Please Log in again.");
      }
      localStorage.removeItem("user");
      setAuth({});
    }
  };

  const getSubmissions = async (assignmentId) => {
    try {
      if (auth?.userType !== "Student") {
        const data = await axios.post(
          `/api/submission/getSubmissionForStation`,
          {
            assignment: assignmentId,
          }
        );
        setSubmissions(data.data);
      }
    } catch (error) {
      console.log(error);
      if (error.response.data === "Token Expired") {
        alert("Your session is expired. Please Log in again.");
      }
      localStorage.removeItem("user");
      setAuth({});
    }
  };

  return (
    <Routes>
      <Route path="" element={<Home getUser={getMentor} stations={stations} />}>
        <Route path="" element={<Stations stations={stations} />} />
        {/* route to show the available options  */}
        <Route
          path=":stationId/options"
          element={
            <StationOptions
              navbarAssignments={navbarAssignments}
              getAssignments={getAssignments}
            />
          }
        />
        <Route
          path="grading"
          element={<Grading stations ={stations}/>}
        />
        <Route
          path="evaluation"
          element={<Evaluation stations={stations}/>}
        />
        {/* if the option announcement is selected  */}
        <Route
          path=":stationId/options/announcements"
          element={<Announcement />}
        />
        <Route
          path=":stationId/options/report"
          element={<Report 
            students={students} 
            getStudentList={getStudentList}
            
          />}
        />

        {/* if the option stats is selected  */}
        <Route
          path=":stationId/options/statistics"
          element={
            <Statistics
              assignments={navbarAssignments}
              getAssignmentList={getAssignments}
              students={students}
              getStudentList={getStudentList}
            />
          }
        />

        {/* */}
        <Route
          path=":stationId/options/scores"
          element={
            <Scores
              students={students}
              getStudentList={getStudentList}
            />
          }
        />

        <Route path=":stationId/options/quiz" element={<CreateQuiz />} />

        {/* if the option dashboard is selected */}
        <Route
          path=":stationId/options/diary"
          element={
            <Diary
              assignments={navbarAssignments}
              students={students}
              getStudentList={getStudentList}
              getAssignmentList={getAssignments}
              getSubmissionList={getSubmissions}
              submissions={submissions}
            />
          }
        ></Route>
      </Route>
    </Routes>
  );
}
