import { useEffect, useState } from "react";
import axios from "axios";
import useAuth from "../../hooks/useAuth";
import { TextField, FormControlLabel, Checkbox, Button, Menu, MenuItem } from "@mui/material";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Box from "@mui/material/Box";
import { DataGrid } from "@mui/x-data-grid";
import Loading from "../Helpers/Loading/Loading";
import "../Scores/Scores.css";
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import "./Grading.css";


const endSemComponents = [
  { "name": "Quiz - 1", "maxScore": 7 },
  { "name": "Group Discussion - 1", "maxScore": 7 },
  { "name": "Diary - 1", "maxScore": 2.5 },
  { "name": "Observation - 1", "maxScore": 2.5 },
  { "name": "Seminar - 1", "maxScore": 10 },
  { "name": "Observation - 2", "maxScore": 2.5 },
  { "name": "Midsem Project Report", "maxScore": 10 },
  { "name": "Viva Voce - 1", "maxScore": 2 },
  { "name": "Diary - 2", "maxScore": 2.5 },
  { "name": "Quiz - 2", "maxScore": 8 },
  { "name": "Group Discussion - 2", "maxScore": 8 },
  { "name": "Diary - 3", "maxScore": 2.5 },
  { "name": "Observation - 3", "maxScore": 2.5 },
  { "name": "Seminar - 2", "maxScore": 10 },
  { "name": "Observation - 4", "maxScore": 2.5 },
  { "name": "Endsem Project Report", "maxScore": 15 },
  { "name": "Viva Voce - 2", "maxScore": 3 },
  { "name": "Diary - 4", "maxScore": 2.5 }
];
const midSemComponents = [
  { "name": "Quiz - 1", "maxScore": 7 },
  { "name": "Group Discussion - 1", "maxScore": 7 },
  { "name": "Diary - 1", "maxScore": 2.5 },
  { "name": "Observation - 1", "maxScore": 2.5 },
  { "name": "Seminar - 1", "maxScore": 10 },
  { "name": "Observation - 2", "maxScore": 2.5 },
  { "name": "Midsem Project Report", "maxScore": 10 },
  { "name": "Viva Voce - 1", "maxScore": 2 },
  { "name": "Diary - 2", "maxScore": 2.5 },
];
const endsemColumns = [
  {
    field: "fullName",
    headerName: "Full Name",
    width: 230,
    editable: false,
  },
  {
    field: "email",
    headerName: "Email",
    width: 230,
    editable: false,
  },
  {
    field: "stationID",
    headerName: "Station ID",
    width: 150,
    editable: false,
  },
  {
    field: "stationName",
    headerName: "Station Name",
    width: 300,
    editable: false,
  },
  {
    field: "total",
    headerName: "Total",
    width: 150,
    editable: false,
    valueGetter: (params) => {
      let total = 0;
      endSemComponents.forEach(info => {
        const field = info.name.toLowerCase().replace(/ /g, '');
        total += params.row[field] || 0;
      });
      return total;
    }
  },
  ...endSemComponents.map(info => ({
    field: info.name.toLowerCase().replace(/ /g, ''),
    headerName: info.name,
    type: "number",
    width: 200,
    editable: true,
    preProcessEditCellProps: (params) => {
      const hasError = params.props.value > info.maxScore;
      if (hasError) {
        toast.error(`Max score of ${info.maxScore} exceeded for ${info.name}`);
      }
      return { ...params.props, error: hasError };
    }
  }))
];

const midsemColumns = [
  {
    field: "fullName",
    headerName: "Full Name",
    width: 230,
    editable: false,
  },
  {
    field: "email",
    headerName: "Email",
    width: 230,
    editable: false,
  },
  {
    field: "stationID",
    headerName: "Station ID",
    width: 150,
    editable: false,
  },
  {
    field: "stationName",
    headerName: "Station Name",
    width: 300,
    editable: false,
  },
  {
    field: "total",
    headerName: "Total",
    width: 150,
    editable: false,
    valueGetter: (params) => {
      let total = 0;
      endSemComponents.forEach(info => {
        const field = info.name.toLowerCase().replace(/ /g, '');
        total += params.row[field] || 0;
      });
      return total;
    }
  },
  ...midSemComponents.map(info => ({
    field: info.name.toLowerCase().replace(/ /g, ''),
    headerName: info.name,
    type: "number",
    width: 200,
    editable: true,
    preProcessEditCellProps: (params) => {
      const hasError = params.props.value > info.maxScore;
      if (hasError) {
        toast.error(`Max score of ${info.maxScore} exceeded for ${info.name}`);
      }
      return { ...params.props, error: hasError };
    }
  }))
];
const grades = [
  ["A", "A-"],
  ["B", "B-"],
  ["C", "C-"],
  ["D", "NC"],
];

export default function Grading(props) {
  let stations = props?.stations;
  let component = props?.component || "endsem";
  const [students, setStudents] = useState([]);
  const [rows, setRows] = useState([]);
  const [bounds, setBounds] = useState({
    gradeBounds: {
      A: { upperBound: 100, lowerBound: 90 },
      "A-": { upperBound: 89, lowerBound: 80 },
      B: { upperBound: 79, lowerBound: 70 },
      "B-": { upperBound: 69, lowerBound: 60 },
      C: { upperBound: 59, lowerBound: 50 },
      "C-": { upperBound: 49, lowerBound: 40 },
      D: { upperBound: 39, lowerBound: 30 },
      NC: { upperBound: 29, lowerBound: 0 },
    },
  });
  const [selectedGrades, setSelectedGrades] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  
  const { auth, setAuth } = useAuth();
  const onCellEditCommit = async (params) => {
    const { id, field, value } = params;
    // console.log("Editing cell:", id, field, value);

    const updatedRows = rows.map((row) =>
      row.id === id ? { ...row, [field]: value } : row
    );
    // console.log("Updated rows:", updatedRows);
    setRows(updatedRows);
    // setChange((prev) => !prev);
  };
  const getAllStudents = async () =>{
    try{
        if (auth?.userType !== "Student") {
            // console.log(auth._id);
          const data = await axios.post(`/api/grades/getScoresForAllStationsOfMentor`, auth);
          // console.log(data.data);
          setStudents(data.data);
        }
    }catch(error){
        console.log(error);
      if (error.response.data === "Token Expired") {
        alert("Your session is expired. Please Log in again.");
      }
      localStorage.removeItem("user");
      setAuth({});
    }
  }
  const handleFetchAllRows = async()=>{
    await getAllStudents();
    const newRows = students?.map((student) => {
        const scores = {};
        
        // if ( !component || component === "endsem") {
        endSemComponents?.forEach(info => {
          const field = info.name.toLowerCase().replace(/ /g, '');
          scores[field] = student?.scores[info.name];
        });
        // }
        // else {
          // midSemComponents?.forEach(info => {
          //   const field = info.name.toLowerCase().replace(/ /g, '');
          //   scores[field] = student?.scores[info.name];
          // });
        // }
      
        return {
          id: student?._id,
          fullName: student?.name,
          email: student?._id,
          stationID: student?.station?._id,
          stationName: student?.station?.name,
          ...scores
        };
      });
      setRows(newRows);
      // console.log("updated")
  }
  const handleDownload = async () => {
    try {
      let temp = auth;
      temp.component = props.component;
      console.log(temp.component);
      if (auth?.userType !== "Student") {
        const response = await axios.post(`/api/grades/getGradesForAllStationsForMentor`, temp, {
          responseType: 'blob',
        });
        if (response.status === 200) {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const a = document.createElement('a');
          a.href = url;
          a.download = 'Report.xlsx';
          document.body.appendChild(a);
          a.click();
          a.remove();
          window.URL.revokeObjectURL(url);
        } else {
          toast.error('There was an error in downloading the file');
        }
      }
    } catch (error) {
      console.log(error);
      // if (error.response.data === "Token Expired") {
      //   alert("Your session is expired. Please Log in again.");
      // }
      // localStorage.removeItem("user");
      // setAuth({});
    }
  }
    const handleFetchFirstStationBounds = async () => {
      if (stations.length === 0) {
        return;
      }
      try {
        if (auth?.userType !== "Student") {
          const res = await axios.post(`/api/grades/getGradeRange`, {
            station: stations[0]._id
          });
          let resObj = {
            gradeBounds: res.data[0].gradeBounds,
          };
          if (res.status === 200) {
            setBounds(resObj);
            const selected = Object.keys(resObj.gradeBounds).filter(
              (grade) => resObj?.gradeBounds[grade]?.lowerBound !== resObj?.gradeBounds[grade]?.upperBound
            );
            setSelectedGrades(selected);
          }else{
            toast.error('Error fetching grade bounds');
          }
        }
        
      } catch (error) {
        if (error.response.data === "Token Expired") {
          alert("Your session is expired. Please Log in again.");
          localStorage.removeItem("user");
          setAuth({});
        }else {
          toast.error("Error in fetching grade bounds");
        }
    }
  }
  useEffect(() => {
    console.log(rows)
  },[rows]);
  useEffect(() => {
    handleFetchAllRows();
    console.log(props)
    //confirm how are we supposed to fetch this
    handleFetchFirstStationBounds();
  }, [students?.length, props.stations]);
  const handleGradeBoundChange = (gradeName, boundType, newValue) => {
    // console.log(gradeName, boundType, newValue);
    setBounds((prevBounds) => ({
      ...prevBounds,
      gradeBounds: {
        ...prevBounds.gradeBounds,
        [gradeName]: {
          ...prevBounds.gradeBounds[gradeName],
          [boundType]: newValue,
        },
      },
    }));
  };
  const handleSaveAllScores = async () => {
    try {
        const scoresData = rows.map(row => {
            const scores = {};
            endSemComponents.forEach(info => {
            const field = info.name.toLowerCase().replace(/ /g, '');
            scores[info.name] = row[field];
            });
            return {
            studentEmail: row.email,
            scores: scores
            };
        });

        let res = await axios.post("/api/grades/setBulkScore", {
            scoresData: scoresData,
        });
        if (res.status === 200) {
          toast.success("Scores saved");
        }
        else{
            toast.error("Error in saving scores");
        }
        } catch (error) {
        console.log(error);
        if (error.response.data === "Token Expired") {
          alert("Your session is expired. Please Log in again.");
          localStorage.removeItem("user");
          setAuth({});
        }else{
            toast.error("Error in saving scores");
        }

    }
  };
  const validateGradeBounds = (gradeBounds) => {
    console.log(gradeBounds);
    const gradeOrder = ['A', 'A-', 'B', 'B-', 'C', 'C-', 'D', 'NC'];
  
    for (let i = 0; i < gradeOrder.length; i++) {
      const grade = gradeOrder[i];
      const { lowerBound, upperBound } = gradeBounds[grade];
  
      if (lowerBound > upperBound) {
        toast.error(`Invalid bounds for ${grade}: Lower bound ${lowerBound} must be less than upper bound ${upperBound}`);
        return false;
      }
  
      if (i > 0) {
        const prevGrade = gradeOrder[i - 1];
        if (upperBound > gradeBounds[prevGrade].lowerBound) {
          toast.error(`Invalid bounds: ${grade} upper bound ${upperBound} must be less than ${gradeBounds[prevGrade].lowerBound}`);
          return false;
        }
      }
  
      if (i < gradeOrder.length - 1) {
        const nextGrade = gradeOrder[i + 1];
        if (lowerBound < gradeBounds[nextGrade].upperBound) {
          toast.error(`Invalid bounds: ${grade} lower bound ${lowerBound} must be greater than ${gradeBounds[nextGrade].upperBound}`);
          return false;
        }
      }
    }
  
    return true;
  };
  const handleSaveAllGradeBounds = async () => {
    // console.log(bounds);
    const updatedBounds = { ...bounds.gradeBounds };

    const gradeOrder = grades.flat();
    gradeOrder.forEach((grade, index) => {
      if (!selectedGrades.includes(grade)) {
        const prevGrade = gradeOrder[index - 1];
        if (prevGrade) {
          const prevLowerBound = updatedBounds[prevGrade]?.lowerBound ?? 0;
          updatedBounds[grade] = { upperBound: prevLowerBound, lowerBound: prevLowerBound };
        } else {
          updatedBounds[grade] = { upperBound: 0, lowerBound: 0 };
        }
      }
    });
    updatedBounds['NC'].lowerBound = 0;
    const isValid = validateGradeBounds(updatedBounds);
    if (!isValid) {
      return;
    }
    setBounds({ gradeBounds: updatedBounds });
    try {
      if (auth.userType !== "Student") {
        let res = await axios.post('api/grades/setGradeBoundsForAllStationsOfMentor', {
          gradeBounds: updatedBounds,
        });
        if (res.status === 200) {
          toast.success("Grade Bounds Set Successfully");
        } else {
          toast.error("Error in saving grade bounds");
        }
      }
    } catch (error) {
      console.log(error);
      if (error.response.data === "Token Expired") {
        alert("Your session is expired. Please Log in again.");
        localStorage.removeItem("user");
        setAuth({});
      } else {
        toast.error("Error in saving grade bounds");
      }
    }
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleGradeSelection = (grade) => {
    setSelectedGrades((prevSelected) =>
      prevSelected.includes(grade)
        ? prevSelected.filter((g) => g !== grade)
        : [...prevSelected, grade]
    );

  };


  return (
    <div className="student-diary">
      <div className="grid-container">
        <h1 style={{ textAlign: "center", paddingBottom: "2rem" }}>Student Scores</h1>
        {rows?.length > 0 ? (
          <>
            <Box sx={{ height: 400, width: "100%" }}>
              <DataGrid
                rows={rows}
                columns={component === "endsem" ? endsemColumns : midsemColumns}
                onCellEditCommit={onCellEditCommit}
                onCellDoubleClick={() => {}}
                initialState={{
                  pagination: {
                    paginationModel: {
                      pageSize: 5,
                    },
                  },
                }}
                pageSizeOptions={[5]}
                disableRowSelectionOnClick
              />
            </Box>
            <div className="diary-options">
              <div className="diary-options-child" onClick={handleSaveAllScores}>
                Save Scores
              </div>
            </div>
          </>
        ) : (
          <Loading />
        )}
      </div>
      <div className="diary-question-container">
        <div style={{display:"flex", justifyContent:"center", alignContent:"center", paddingBottom:"2rem", gap:"1rem"}}>
          <h1 style={{ textAlign: "center" }}>Set Grade Bounds</h1>
          
          <Button
            aria-controls={open ? 'grade-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
            onClick={handleClick}
          >
            <FilterAltIcon style={{fontSize:"2rem", color:'black'}}/>
            <span style={{color:"black"}}>
              FILTER
            </span>
          </Button>
        </div>
        
        <div style={{ textAlign: "center", marginBottom: "2rem", marginTop:"-1rem" }}>
          <span style={{fontWeight: 800}}>NOTE: </span>
            This will override all existing grade bounds for all stations
        </div>

        <Menu
          id="grade-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
        >
          {grades.flat().map((grade) => (
            <MenuItem key={grade}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={selectedGrades.includes(grade)}
                    onChange={() => handleGradeSelection(grade)}
                  />
                }
                label={grade}
              />
            </MenuItem>
          ))}
        </Menu>
        <Box sx={{ height: "100%", width: "100%" }}>
          {grades.flat().filter(grade => selectedGrades.includes(grade)).map((grade, index) => (
            <Box
              display="flex"
              flex={1}
              flexDirection="row"
              alignContent="center"
              justifyContent="center"
              key={index}
              marginBottom={4}
              alignItems="center"
            >
              <div style={{ }}>{grade}</div>
              <TextField
                type="number"
                variant="outlined"
                size="small"
                value={bounds?.gradeBounds[grade]?.lowerBound}
                onChange={(e) =>
                  handleGradeBoundChange(
                    grade,
                    "lowerBound",
                    parseFloat(isNaN(e.target.value) ? 0 : e.target.value)
                  )
                }
                sx={{
                  marginRight: 1,
                  marginLeft: 1,
                  "@media screen and (max-width: 600px)": {
                    marginRight: "2px",
                    marginLeft: "2px",
                  },
                }}
              />
              to
              <TextField
                type="number"
                variant="outlined"
                value={bounds?.gradeBounds[grade]?.upperBound}
                sx={{
                  marginRight: 10,
                  marginLeft: 1,
                  "@media screen and (max-width: 600px)": {
                    marginRight: "10px",
                    marginLeft: "2px",
                  },
                }}
                onChange={(e) =>
                  handleGradeBoundChange(
                    grade,
                    "upperBound",
                    parseInt(e.target.value)
                  )
                }
                size="small"
              />
            </Box>
          ))}
          <div className="diary-options">
            <div className="diary-options-child" onClick={handleSaveAllGradeBounds}>
              Save Bounds
            </div>
            <div className="diary-options-child" onClick={handleDownload}>
              Download Grades
            </div>
          </div>
        </Box>
      </div>
    </div>
  );
}
